const shpwrite = require('shp-write'),
  clone = require('clone'),
  geojson2dsv = require('geojson2dsv'),
  topojson = require('topojson-server'),
  saveAs = require('file-saver'),
  tokml = require('@placemarkio/tokml'),
  geojsonNormalize = require('@mapbox/geojson-normalize'),
  wellknown = require('wellknown');

const flash = require('./flash'),
  zoomextent = require('../lib/zoomextent'),
  readFile = require('../lib/readfile'),
  meta = require('../lib/meta.js'),
  routeBuilder = require('./route_builder.js'),
  overpass = require('./overpass.js');

/**
 * This module provides the file picking & status bar above the map interface.
 * It dispatches to source implementations that interface with specific
 * sources, like GitHub.
 */
module.exports = function fileBar(context) {
  const shpSupport = typeof ArrayBuffer !== 'undefined';

  const exportFormats = [
    {
      title: 'GeoJSON',
      action: downloadGeoJSON
    },
    {
      title: 'TopoJSON',
      action: downloadTopo
    },
    {
      title: 'CSV',
      action: downloadDSV
    },
    {
      title: 'KML',
      action: downloadKML
    },
    {
      title: 'WKT',
      action: downloadWKT
    }
  ];

  if (shpSupport) {
    exportFormats.push({
      title: 'Shapefile',
      action: downloadShp
    });
  }

  function bar(selection) {
    const actions = [
      {
        title: 'Import',
        alt: 'CSV, GTFS, KML, GPX, and other filetypes',
        action: blindImport
      },
      {
        title: 'Export',
        children: exportFormats
      },
      {
        title: 'New',
        action: function () {
          window.open(
            window.location.origin + window.location.pathname + '#new'
          );
        }
      },
      {
        title: 'Tools',
        action: function () {},
        children: [
          {
            title: 'Add raster tile layer',
            alt: 'Add a custom tile layer',
            action: function () {
              const layerURL = prompt(
                'Layer URL\ne.g. https://stamen-tiles-b.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'
              );
              if (layerURL === null) return;
              const layerName = prompt('Layer name');
              if (layerName === null) return;
              meta.adduserlayer(context, layerURL, layerName);
            }
          },
          {
            title: 'Create COG TileJSON',
            alt: 'Create COG TileJSON',
            action: function () {
              const geoTiffUrl = prompt(
                'Cloud optimized GeoTIFF URL\ne.g. https://prd-tnm.s3.amazonaws.com/StagedProducts/Maps/HistoricalTopo/GeoTIFF/NV/NV_Ely_321587_1952_125000_geo.tif'
              );
              if (geoTiffUrl === null) return;
              downloadTileJSON(geoTiffUrl);
            }
          },
          {
            title: 'Zoom to features',
            alt: 'Zoom to the extent of all features',
            action: function () {
              meta.zoomextent(context);
            }
          },
          {
            title: 'Start route builder',
            alt: 'Start route builder',
            action: function () {
              if (!routeBuilder.hasRouteBuilder()) {
                const confirmMessage =
                  'Click on the map to select points along your route. ' +
                  'Successive points must be within 40 miles (65 km) of each other.\n\n' +
                  'When complete, select "Stop route builder" from the Tools menu.\n\n' +
                  'Select "Cancel" if you do not want to see this message again, otherwise select "OK".';
                setRouteBuilderConfirm(confirmMessage);
                routeBuilder.startRoute();
                setRouteBuilderMenu(
                  'Start route builder',
                  'Stop route builder'
                );
              } else {
                if (routeBuilder.hasRoute()) {
                  if (confirm('Stop route builder?')) {
                    if (
                      confirm(
                        'Press "OK" to draw a navigation style route line, otherwise "Cancel" for a regular line.'
                      )
                    ) {
                      routeBuilder.stopRoute(context, true);
                    } else {
                      routeBuilder.stopRoute(context, false);
                    }
                    setRouteBuilderMenu(
                      'Stop route builder',
                      'Start route builder'
                    );
                  }
                } else {
                  routeBuilder.stopRoute(context, false);
                  setRouteBuilderMenu(
                    'Stop route builder',
                    'Start route builder'
                  );
                }
              }
            }
          },
          {
            title: 'Get offroad trails',
            alt: 'Get offroad trails from OpenStreetMap',
            action: function () {
              if (context.map.getZoom() < 10) {
                alert('Zoom in to reduce map area');
              } else {
                overpass.getTrails(context);
              }
            }
          },

          {
            title: 'Clear',
            alt: 'Delete all features from the map',
            action: function () {
              if (
                confirm(
                  'Are you sure you want to delete all features from this map?'
                )
              ) {
                meta.clear(context);
                routeBuilder.clear();
                setRouteBuilderMenu(
                  'Stop route builder',
                  'Start route builder'
                );
              }
            }
          }
        ]
      },
      {
        title: 'Help',
        action: function () {},
        children: [
          {
            title: 'Open help',
            alt: 'Open help',
            action: function () {
              window.open('help.html', 'NavOffroad Help');
            }
          },
          {
            title: 'Watch demo video',
            alt: 'Watch demo video',
            action: function () {
              window.open('youtube.html', 'NavOffroad Video');
            }
          },
          {
            title: 'Get the iOS app',
            alt: 'Get the iOS app',
            action: function () {
              const qrCodeImage = document.getElementById('qrcodeImage');
              if (qrCodeImage.style.display === 'none') {
                qrCodeImage.style.display = 'block';
              } else {
                qrCodeImage.style.display = 'none';
              }
            }
          },
          {
            title: 'Donate',
            alt: 'Donate',
            action: function () {
              window.open('https://buymeacoffee.com/navoffroad', 'Donate');
            }
          }
        ]
      }
    ];

    const items = selection
      .append('div')
      .attr('class', 'inline')
      .selectAll('div.item')
      .data(actions)
      .enter()
      .append('div')
      .attr('class', 'item');

    items
      .append('a')
      .attr('class', 'parent')
      .on('click', function (d) {
        if (d.action) d.action.apply(this, d);
      })
      .text((d) => {
        return ' ' + d.title;
      });

    items.each(function (d) {
      if (!d.children) return;
      d3.select(this)
        .append('div')
        .attr('class', 'children')
        .call(submenu(d.children));
    });

    function submenu(children) {
      return function (selection) {
        selection
          .selectAll('a')
          .data(children)
          .enter()
          .append('a')
          .attr('title', (d) => {
            if (
              d.title === 'File' ||
              d.title === 'Add map layer' ||
              d.title === 'Zoom to features' ||
              d.title === 'Clear' ||
              d.title === 'Flatten Multi Features'
            )
              return d.alt;
          })
          .text((d) => {
            return d.title;
          })
          .on('click', function (d) {
            d.action.apply(this, d);
          });
      };
    }

    function blindImport() {
      const put = d3
        .select('body')
        .append('input')
        .attr('type', 'file')
        .attr(
          'accept',
          '.txt,.xml,.json,.geojson,.topojson,.kml,.gpx,.csv,application/json,application/xml'
        )
        .style('visibility', 'hidden')
        .style('position', 'absolute')
        .style('height', '0')
        .on('change', function () {
          const files = this.files;
          if (!(files && files[0])) return;
          readFile.readAsText(files[0], (err, text) => {
            readFile.readFile(files[0], text, onImport);
            if (files[0].path) {
              context.data.set({
                path: files[0].path
              });
            }
          });
          put.remove();
        });
      put.node().click();
    }

    function onImport(err, gj, warning) {
      gj = geojsonNormalize(gj);
      if (gj) {
        context.data.mergeFeatures(gj.features);
        if (warning) {
          flash(context.container, warning.message);
        } else {
          flash(
            context.container,
            'Imported ' + gj.features.length + ' features.'
          ).classed('success', 'true');
        }
        zoomextent(context);
      }
    }

    d3.select(document).call(
      d3.keybinding('file_bar').on('⌘+o', () => {
        blindImport();
        d3.event.preventDefault();
      })
    );
  }

  function setRouteBuilderMenu(title, newTitle) {
    const toolsMenuItem = Array.from(document.querySelectorAll('.item')).find(
      (item) => {
        const parentLink = item.querySelector('.parent');
        return parentLink && parentLink.textContent.trim() === 'Tools';
      }
    );

    if (toolsMenuItem) {
      const childrenMenu = toolsMenuItem.querySelector('.children');
      const menuItem = Array.from(childrenMenu.getElementsByTagName('a')).find(
        (a) => a.textContent.trim() === title
      );
      if (menuItem) {
        menuItem.textContent = newTitle;
      }
    }
  }

  function setRouteBuilderConfirm(message) {
    // Check if the user has previously chosen not to see the confirm dialog
    if (localStorage.getItem('hideRouteBuilderConfirm') !== 'true') {
      const userConfirmed = confirm(message);
      if (!userConfirmed) {
        localStorage.setItem('hideRouteBuilderConfirm', 'true');
      }
    }
  }

  function downloadTopo() {
    const content = JSON.stringify(
      topojson.topology(
        {
          collection: clone(context.data.get('map'))
        },
        { 'property-transform': allProperties }
      )
    );

    saveAs(
      new Blob([content], {
        type: 'application/json;charset=utf-8'
      }),
      'map.topojson'
    );
  }

  function downloadGeoJSON() {
    if (d3.event) d3.event.preventDefault();
    const content = JSON.stringify(context.data.get('map'));
    const meta = context.data.get('meta');
    saveAs(
      new Blob([content], {
        type: 'application/json;charset=utf-8'
      }),
      (meta && meta.name) || 'map.geojson'
    );
  }

  function downloadDSV() {
    if (d3.event) d3.event.preventDefault();
    const content = geojson2dsv(context.data.get('map'));
    saveAs(
      new Blob([content], {
        type: 'text/plain;charset=utf-8'
      }),
      'points.csv'
    );
  }

  function downloadKML() {
    if (d3.event) d3.event.preventDefault();
    const content = tokml.toKML(context.data.get('map'));
    saveAs(
      new Blob([content], {
        type: 'application/xml;charset=utf-8'
      }),
      'map.kml'
    );
  }

  function downloadShp() {
    if (d3.event) d3.event.preventDefault();
    d3.select('.map').classed('loading', true);
    try {
      shpwrite.download(context.data.get('map'));
    } finally {
      d3.select('.map').classed('loading', false);
    }
  }

  function downloadWKT() {
    if (d3.event) d3.event.preventDefault();
    const features = context.data.get('map').features;
    if (features.length === 0) return;
    const content = features.map(wellknown.stringify).join('\n');
    saveAs(
      new Blob([content], {
        type: 'text/plain;charset=utf-8'
      }),
      'map.wkt'
    );
  }

  async function downloadTileJSON(geoTiffUrl) {
    document.getElementById('spinner').style.display = 'block';
    try {
      const response = await fetch('./USGSHistoricalTopo.json'); // Replace with your JSON file path
      const data = await response.json();
      const matchingObject = data.find((obj) => obj.url === geoTiffUrl);
      const fileName = matchingObject
        ? `${matchingObject.name} - ${matchingObject.date}.json`
        : `tileJson.json`;
      const tileJSONResponse = await fetch(
        `https://titiler.usgs.gov/cog/tilejson.json?url=${encodeURIComponent(
          geoTiffUrl
        )}`
      );
      if (tileJSONResponse.status === 200) {
        const tileJSON = await tileJSONResponse.json();
        if (tileJSON.center) {
          delete tileJSON.center; // Remove the center object
        }
        if (matchingObject) {
          tileJSON.bounds = [
            parseFloat(matchingObject.w),
            parseFloat(matchingObject.s),
            parseFloat(matchingObject.e),
            parseFloat(matchingObject.n)
          ];
        }
        const blob = new Blob([JSON.stringify(tileJSON, null, 2)], {
          type: 'application/json'
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log(`Downloaded ${fileName} successfully.`);
      } else {
        alert('Failed to create TileJSON');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create TileJSON');
    }
    document.getElementById('spinner').style.display = 'none';
  }

  function allProperties(properties, key, value) {
    properties[key] = value;
    return true;
  }

  return bar;
};
